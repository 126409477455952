import React, { useState, Component } from "react";
import { http } from "../helper";
import Header from "../common/layout/header";
import Footer from "../common/layout/footer";
import { Link } from "react-router-dom";
import { BaseUrl } from "../common/config";
import Loader from "../common/loader";

const permissionToRouteMap = {
  manage_freeproducts: "/freeproducts",
  manage_newproducts: "/newproducts",
  manage_customers: "/vendors",
  manage_orders: "/orders",
  manage_masterentry: "/master-entry/productgroup",
  manage_settings: "/settings",
  manage_products: "/products",
};

const orderedPermissions = [
  "manage_dashboard",
  "manage_products", // Products
  "manage_freeproducts", // Free products
  "manage_newproducts", // New products
  "manage_customers", // Customers
  "manage_orders", // Orders
  "manage_masterentry", // Master Entry
  "manage_settings", // Settings
  "manage_roles", // Roles
  "manage_users", // Users
];

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loginStatus: "",
      loginMessage: "",
      isLoading: false,
    };

    this.emailChange = this.emailChange.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
    //Access the permission routes
  }

  componentDidMount() {
    if (sessionStorage.getItem("isLoggedIn"))
      window.location.href = "/products";
  }

  validateEmail($email) {
    //var emailRegex = /^[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/;
    var emailRegex =
      /^[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g;
    if (!emailRegex.test($email)) {
      return false;
    } else {
      return true;
    }
  }

  emailChange(event) {
    this.setState({
      email: event.target.value,
    });
    this.setState({ loginStatus: "", loginMessage: "" });
  }

  passwordChange(event) {
    this.setState({
      password: event.target.value,
    });
    this.setState({ loginStatus: "", loginMessage: "" });
  }

  loginOnSubmit = (event) => {
    event.preventDefault();
    let URL = BaseUrl + "admin/login";
    if (this.validateEmail(this.state.email.toLowerCase().trim())) {
      let payload = {
        email: this.state.email.toLowerCase().trim(),
        password: this.state.password,
      };
      this.setState({ isLoading: true });
      http
        .post(URL, payload)
        .then((response) => {
          if (response.data.hasOwnProperty("data")) {
            if (response.data.data.status === 200) {
              const userPermissions = response.data.data.adminRole.permissions;

              const accessibleRoute = orderedPermissions
                .filter((permission) => userPermissions.includes(permission))
                .map((permission) => permissionToRouteMap[permission])
                .find((route) => !!route);
              const defaultRoute = "/dashboard"; //default url
              if (userPermissions.includes("manage_dashboard")) {
                window.location = "/dashboard";
              } else {
                // Redirect to the first accessible route or a default page
                window.location.href = accessibleRoute || defaultRoute;
              }
              sessionStorage.setItem("isLoggedIn", true);
              sessionStorage.setItem(
                "adminEmail",
                response.data.data.adminEmail
              );
              sessionStorage.setItem("uuid", response.data.data.uuid);
              sessionStorage.setItem("token", response.data.data.token);
              sessionStorage.setItem(
                "authData",
                JSON.stringify(response.data.data)
              );
            } else if (response.data.data.status !== 200) {
              this.setState({ loginMessage: response.data.data.message });
              this.setState({ loginStatus: response.data.data.status });
            }
          }
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ loginStatus: 401 });
      this.setState({ loginMessage: "Please enter valid Email Address" });
    }
  };

  render() {
    return (
      <div className="login-page">
        <Header />
        {!sessionStorage.getItem("isLoggedIn") && !this.state.isLoading && (
          <div className="login">
            <div className="form">
              <form className="login-form" onSubmit={this.loginOnSubmit}>
                <h2>Admin Login</h2>
                <div className="form-field input-field">
                  {this.state.loginStatus ? (
                    this.state.loginStatus === 401 ? (
                      <div className="error-msg login-error">
                        {this.state.loginMessage}
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <input
                    type="text"
                    id="accountEmail"
                    value={this.state.email}
                    placeholder="Email Address"
                    required
                    onChange={this.emailChange}
                  />
                </div>
                <div className="form-field input-field">
                  <input
                    type="password"
                    id="accountPassword"
                    value={this.state.password}
                    placeholder="Password"
                    required
                    onChange={this.passwordChange}
                  />
                </div>
                <button type="submit">login</button>
              </form>
              <div className="forget-pass-field">
                <Link className="pass-reset" to="/forgot-password">
                  Forgot or Reset Password
                </Link>
              </div>
            </div>
          </div>
        )}
        {this.state.isLoading && <Loader propsClassName="load-lag" />}
        <Footer />
      </div>
    );
  }
}

export default Login;
