import React, { useState, useEffect } from 'react';
import Header from '../common/layout/header'; 
import Footer from '../common/layout/footer'; 
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
import CommonAlert from '../common/Commonalert';
import { http } from '../helper';

const NotificationSettings = () =>{

 
    const [notification_txt, setNotification_Txt] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [errormsg,setErrormsg] = useState({});
    const [errmessage, setErrMessage] = useState({});
    const [alertPopup,setAlertPopup] = useState(false);
    const [alertMessage,setAlertMessage] = useState([]);
    const [responsiveDesign, setResponsiveDesign] = useState("header");
    const [textresponsiveDesign, setTextResponsiveDesign] = useState("left");
    const [desktopDesign, setDesktopDesign] = useState("header");
    const [textdesktopDesign, setTextDesktopDesign] = useState("left");
    const [isLoading, setIsLoading] = useState(true); 
    
  if(!sessionStorage.getItem("isLoggedIn")){
    window.location.href = '/';
  }
  
  if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_settings'))
    {
      window.location.href = '/unauthorized';
    }

    useEffect(()=>{

        let URL = BaseUrl + "admin/notification";
        setIsLoading(true);
        http.get(URL).then((response) => {
            let responseData = response.data.data;
           
            if(response.data.success === true)
            {
                setNotification_Txt(responseData[0].notification_txt);
                (responseData[0].enablenotification === '1') ? setIsChecked(true) : setIsChecked(false); 
                setResponsiveDesign(responseData[0].notificationResponsive);
                setDesktopDesign(responseData[0].notificationDesktop);
                setTextResponsiveDesign(responseData[0].textalignResponsive);
                setTextDesktopDesign(responseData[0].textalignDesktop);
                setIsLoading(false);
            }
                    
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
        })  
    },[])


   const btnsubmit = (e)=>{
    e.preventDefault();
    if (notification_txt.trim() === '') {
        setErrMessage({ notification_txt: "Notification message cannot be empty." });
        return;
    }
    let payload = {
        notification_txt   : notification_txt.trim(),
        enablenotification: (isChecked)?1:0,
        notificationDesktop:desktopDesign,
        notificationResponsive:responsiveDesign,
        textalignDesktop : textdesktopDesign,
        textalignResponsive: textresponsiveDesign,
     }
     setIsLoading(true);
     let URL = BaseUrl + "admin/notification";
     http.post(URL, payload).then(async (response) => {
            
        if (response.data.success === true) {
            setIsLoading(false);
            setAlertPopup(true);
            setAlertMessage("Notification settings saved successfully!");
            }
      }).catch((err) => {
         console.log(err);
         
      })
   

 
    setErrMessage({});
   }   

   

  const handleOnChange = (e) =>{
    const re = /^[0-9\b]+$/;
    if(e.target.name ==="notification_txt")
    {
        setNotification_Txt(e.target.value);
      
    }
    if(e.target.name ==="notification")
    {
        
        setIsChecked(!isChecked);
       
    }
    
    setAlertPopup(false);
  }

    return (<section className="dashboard-block">
{alertPopup && ( <CommonAlert title='Alert' message={alertMessage}/> )}
    
    <Sidebar />
   
<div className="dash-left">
<Header/>
    <section className="cart-block">
        <div className="container account-details">
        <h1 className="checkout-title">Notification Settings</h1>

        <div className="right-table-outer-wrap">
        {isLoading  ? (
                <div className='loading-buffer'></div>
            ):(
        <div className='form-field form-container-xs'>
                    <form className='client-detail-form'>
                        <div className='input-field col-1'>
                            <label for='name'>Notification Message</label>
                            <textarea name="notification_txt" cols="50" rows="5" value={notification_txt} onChange={handleOnChange}/>
                            
                            {errmessage.notification_txt  && <div className='error-msg'>{errmessage.notification_txt}</div> }  
                        </div>
                        <div className='input-field col-1'>
                            <label for='name'>Enable Notification</label>
                            <div className="switch-button">
                                <input type="checkbox" className="checkbox" name="notification" id="notification" checked={isChecked}
                            onChange={handleOnChange} />
                                <div className="knobs">
                                    <span></span>
                                </div>
                                <div className="layer"></div>
                            </div>
                        </div>

                        <div className='notification-field-set-wrap'>
                            <div className='notification-field-set'>
                                <h3>Responsive</h3>
                                <div className="input-field col-1">
                                    <label>Notification Design</label>
                                    <div className="check-box">
                                        <input
                                            type="radio"
                                            id="setheader-responsive"
                                            name="notificationResponsive"
                                            checked={responsiveDesign === "header"}
                                            onChange={() => {setResponsiveDesign("header");setAlertPopup(false);}}
                                        />
                                        <label htmlFor="setheader-responsive">Top Header</label>
                                    </div>
                                    <div className="check-box">
                                        <input
                                            type="radio"
                                            id="setpopup-responsive"
                                            name="notificationResponsive"
                                            checked={responsiveDesign  === "popup"}
                                            onChange={() => {setResponsiveDesign("popup");setAlertPopup(false);}}
                                        />
                                        <label htmlFor="setpopup-responsive">Popup</label>
                                    </div>
                                    
                                </div>

                                <div className="input-field col-1">
                                    <label>Text Alignment</label>
                                    <div className="check-box">
                                        <input
                                            type="radio"
                                            id="setlefttext-responsive"
                                            name="textResponsive"
                                            checked={textresponsiveDesign  === "left"}
                                            onChange={() => {setTextResponsiveDesign("left");setAlertPopup(false);}}
                                        />
                                        <label htmlFor="setlefttext-responsive">Left</label>
                                    </div>
                                    <div className="check-box">
                                        <input
                                            type="radio"
                                            id="setcentertext-responsive"
                                            name="textResponsive"
                                            checked={textresponsiveDesign  === "center"}
                                            onChange={() => {setTextResponsiveDesign("center");setAlertPopup(false);}}
                                        />
                                        <label htmlFor="setcentertext-responsive">Center</label>
                                    </div>
                                </div>
                            </div>

                            <div className='notification-field-set'>
                                <h3>Desktop</h3>
                                <div className="input-field col-1">
                                    <label>Notification Design</label>
                                    <div className="check-box">
                                        <input
                                            type="radio"
                                            id="setheader-desktop"
                                            name="notificationDesktop"
                                            checked={desktopDesign === "header"}
                                            onChange={() => {setDesktopDesign("header");setAlertPopup(false);}}
                                        />
                                        <label htmlFor="setheader-desktop">Top Header</label>
                                    </div>
                                    <div className="check-box">
                                        <input
                                            type="radio"
                                            id="setpopup-desktop"
                                            name="notificationDesktop"
                                            checked={desktopDesign  === "popup"}
                                            onChange={() => {setDesktopDesign("popup");setAlertPopup(false);}}
                                        />
                                        <label htmlFor="setpopup-desktop">Popup</label>
                                    </div>
                                    
                                </div>

                                <div className="input-field col-1">
                                    <label>Text Alignment</label>
                                    <div className="check-box">
                                        <input
                                            type="radio"
                                            id="setlefttext-desktop"
                                            name="textDesktop"
                                            checked={textdesktopDesign  === "left"}
                                            onChange={() => {setTextDesktopDesign("left");setAlertPopup(false);}}
                                        />
                                        <label htmlFor="setlefttext-desktop">Left</label>
                                    </div>
                                    <div className="check-box">
                                        <input
                                            type="radio"
                                            id="setcentertext-desktop"
                                            name="textDesktop"
                                            checked={textdesktopDesign  === "center"}
                                            onChange={() => {setTextDesktopDesign("center");setAlertPopup(false);}}
                                        />
                                        <label htmlFor="setcentertext-desktop">Center</label>
                                    </div>
                                </div>
                            </div>
                                                
                            <div className='btnfield'>
                            <button type="submit" name="btnsubmit" onClick={btnsubmit}>Save</button>
                            </div>
                        </div>
                        
                        
                    </form>
                </div>)}
        </div>
        </div>
        <Footer/> 
    </section>
    
        </div>
        
    </section>);

}
export default NotificationSettings;