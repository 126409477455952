import React, { useEffect, useState, memo } from "react";
import { Pie } from "react-chartjs-2";
import { http } from '../helper';
import { BaseUrl } from '../common/config';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';

Chart.register(CategoryScale);
export const Categorysales = memo(({ }) => {
    var currentTime = new Date();
    const [filter, setFilter] = useState(0);
    const [changes, setChanges] = useState(currentTime);
    const [totalamt, setTotalamt] = useState(0);
    const [totalcnt, setTotalcnt] = useState(0);
    const [typeFilter, setTypeFilter] = useState('Qty Sold');
    const [filtertext, setFilterText] = useState('');
    const [pizzaCount, setPizzaCount] = useState(0);
    const [pastaCount, setPastaCount] = useState(0);
    const [burritoCount, setBurritoCount] = useState(0);

    const [pizzzSales, setPizzzSales] = useState(0);
    const [pastaSales, setPastaSales] = useState(0);
    const [burritoSales, setBurritoSales] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const [chartData, setChartData] = useState({
        labels: ['PIZZA', 'PASTA', 'BURRITO'],
        datasets: [
            {
                label: typeFilter === 'Qty Sold' ? "Qty Sold #" : "Sale %",
                data: [pizzaCount, pastaCount, burritoCount],
                backgroundColor: [
                    "rgba(252,194,84,1)",
                    "#f4ecec",
                    "rgba(255,207,0,1)",

                ],
                borderColor: "black",
                borderWidth: 2
            }
        ]
    });

    useEffect(() => {

        let pieData = (typeFilter === 'Qty Sold' ? [pizzaCount, pastaCount, burritoCount] : [pizzzSales, pastaSales, burritoSales])
        setChartData({
            labels: ['PIZZA', 'PASTA', 'BURRITO'],
            datasets: [
                {
                    label: typeFilter === 'Qty Sold' ? "Qty Sold #" : "Sale %",
                    data: pieData,
                    type: 'pie',
                    backgroundColor: [
                        "#a1e8eb",
                        "rgba(195,183,248,1)",
                        "rgba(164,218,184,1)",
                    ],
                    borderColor: "#e6e6e6",
                    borderWidth: 1
                }
            ]
        });
    }, [typeFilter, changes])

    useEffect(() => {
        if (filter === "custom" && (!fromDate || !toDate)) {
            // Don't make the API call if dates are missing for the "custom" filter
            return;
        }
        let payload = {};
        if (filter === 'custom') {
            payload = {
                filter: filter, datefrom: format(new Date(fromDate), "yyyy-MM-dd"),
                dateto: format(new Date(toDate), "yyyy-MM-dd")
            }
            setShowDatePicker(false);
        }
        else {
            payload = {
                filter: parseInt(filter), datefrom: '',
                dateto: ''
            }
        }
        let URL = BaseUrl + "admin/categorysales";
        http.post(URL, payload).then((res) => res.data.data).then((response) => {
            setIsLoading(false);
            let pizzaarr = response.filter((item) => item.categoryId === "1");
            let pastaarr = response.filter((item) => item.categoryId === "10" || item.categoryId === "13");
            let burritoarr = response.filter((item) => item.categoryId === "11" || item.categoryId === "12");
            let finalone = pizzaarr.concat(pastaarr, burritoarr);
            let totalAmount = finalone.reduce((total, item) => {
                return total + item.amount;
            }, 0);
            let totalCount = finalone.reduce((total, item) => {
                return total + item.sum;
            }, 0);

            let pizzaCount = pizzaarr.reduce((total, item) => {
                return total + item.sum;
            }, 0);
            let pizzaAmount = pizzaarr.reduce((total, item) => {
                return total + item.amount;
            }, 0);
            let pastaCount = pastaarr.reduce((total, item) => {
                return total + item.sum;
            }, 0);
            let pastaAmount = pastaarr.reduce((total, item) => {
                return total + item.amount;
            }, 0);
            let burritoCount = burritoarr.reduce((total, item) => {
                return total + item.sum;
            }, 0);
            let burritoAmount = burritoarr.reduce((total, item) => {
                return total + item.amount;
            }, 0);
            let pizzaper = (pizzaAmount / totalAmount) * 100;
            let pastaper = (pastaAmount / totalAmount) * 100;
            let burritoper = (burritoAmount / totalAmount) * 100;

            setPizzzSales(pizzaper.toFixed(2));
            setPastaSales(pastaper.toFixed(2));
            setBurritoSales(burritoper.toFixed(2));

            setBurritoCount(burritoCount);
            setPastaCount(pastaCount);
            setPizzaCount(pizzaCount);

            setTotalamt(totalAmount);
            setTotalcnt(totalCount);

            setChanges(new Date());

        }).catch((err) => {
            console.log(err);
        })
    }, [filter, fromDate, toDate]);
    const DateChange = (value, type) => {
        if (type === "from") {
            setFromDate(value);

            if (toDate && value && value > toDate) {
                setToDate(null);
            }
        } else if (type === "to") {
            if (!fromDate || value >= fromDate) {
                setToDate(value);
            } else {
                // Handle invalid date selection
                //alert("The 'To' date cannot be earlier than the 'From' date.");
                setToDate(null);
            }
        }
    };

    const changeFilterHandler = (e) => {
        setFilter(e.target.value);
        setFilterText(e.target.options[e.target.selectedIndex].text);
        if (e.target.value === 'custom') {
            setShowDatePicker(true);

        } else {
            setShowDatePicker(false);
            setFromDate(null);
            setToDate(null);
            setFilter(e.target.value);

        }
    };
    const changeTypeFilterHandler = (e) => {
        setTypeFilter(e.target.value);

    };

    const legendMargin = {
        id: "legendMargin",
        beforeInit: function (chart) {
            const fitValue = chart.legend.fit;
            chart.legend.fit = function fit() {
                fitValue.bind(chart.legend)();
                return (this.height += -10);
            };
        }
    };

    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, "0");
        const day = String(dateObject.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
    }

    let loadingbuffer = isLoading ? 'loading-buffer' : '';

    return (

        <div className={`chart-container chart-category-sales ${loadingbuffer}`}>
            <div className="chart-Hdr">
                <div className="chart-Hdr-txt">
                    <h2 style={{ textAlign: "left" }}>Category Sales</h2>
                </div>
                <div className="chart-fltr">
                    {<select
                        value={typeFilter}
                        onChange={(e) => changeTypeFilterHandler(e)}

                    >
                        <option value={'Qty Sold'}>Qty Sold</option>
                        <option value={'Sale Percentage'}>Sale %</option>

                    </select>}
                </div>

                <div className="chart-fltr" onMouseEnter={() => {
                    if (filter === "custom" && !showDatePicker) {
                        setShowDatePicker(true);
                    }
                }}
                    onMouseLeave={() => {
                        if (filter === "custom" && showDatePicker) {
                            setShowDatePicker(false);

                        }
                    }}>
                    {<select
                        value={filter}
                        onChange={(e) => changeFilterHandler(e)}

                    ><option value={0}>All</option>
                        <option value={1}>Past 1 Day</option>
                        <option value={30}>Past 30 Days</option>
                        <option value={90}>Past 90 Days</option>
                        <option value={180}>Past 180 Days</option>
                        <option value={365}>Past 1 Year</option>
                        <option value={'custom'}>Custom Date</option>
                    </select>}
                    {showDatePicker && (
                        <div className="date-picker-container">
                            <label>From:</label>
                            <DatePicker
                                selected={fromDate}
                                onChange={(date) => DateChange(date, "from")}
                                showDisabledMonthNavigation
                                dateFormat="dd/MM/yyyy"
                                disablePast={true}
                                placeholderText="Select Date"
                            />
                            <label>To:</label>
                            <DatePicker
                                selected={toDate}
                                showDisabledMonthNavigation
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => DateChange(date, "to")}
                                disablePast={true}
                                placeholderText="Select Date"
                            />
                        </div>
                    )}
                </div>

                <div className="fltr-sub-val">
                    <span className='fltr-val'>{typeFilter === 'Qty Sold' ? "Total Qty Sold: " + totalcnt : "Total Amount (Excl. of VAT): £" + totalamt.toFixed(2)}</span>
                    <span className='fltr-val'>({(filtertext != '' && filtertext != 'Custom Date') ? filtertext : (filtertext === 'Custom Date' && fromDate && toDate) ? formatDate(fromDate) + ' - ' + formatDate(toDate) : 'All'})</span>
                </div>
            </div>
            {chartData && <Pie
                data={chartData}
                plugins={[legendMargin]}
                options={{
                    plugins: {
                        title: {
                            display: false,

                        },
                        legend: {
                            display: true,
                            align: 'left',
                            position: 'bottom',
                            labels: {
                                color: "#969696",
                                font: {
                                    size: 12
                                },
                                boxWidth: 15,
                                padding: 20,
                            },
                        },
                        tooltip: {
                            titleColor: 'rgba(220, 222, 226, 1)',
                            bodyColor: 'rgba(220, 222, 226, 1)'
                        }
                    }
                    
                }}
            />}
        </div>
    );
});