import React, { Component, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../common/layout/header'; 
import Footer from '../common/layout/footer';
import { http } from '../helper';
import { BaseUrl } from '../common/config';
import DataTable from 'react-data-table-component';
import Sidebar from '../common/sidebar';

const MasterEntry = () =>{
    const { name } = useParams();
    const [data, setData] = useState([]);
    const [templist, setTempList] = useState([]);
    const [searchTextVal,setSearchTextVal] = useState([]);
    const [mastervalue, setMastervalue] = useState([]);
    const [isLoading, setIsLoading] = useState(true);    
    const [visibleAlert,setVisibleAlert] = useState(false);

    if(!sessionStorage.getItem("isLoggedIn"))
        window.location.href = '/';
  
    if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_masterentry'))
        {
          window.location.href = '/unauthorized';
        }
    useEffect(()=>{
        fetchData(); 
    },[name])

    const editMaster = (value)=>{
        window.location.href = `/master-entry/edit/${name}/${value._id}`;
    }
    const deleteMaster = (value) =>{
        setVisibleAlert(true);
        setMastervalue(value);
    }
  
    const fetchData = async () => {
        setIsLoading(true);
        try {
        var loginDetails = {};
        if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
        }       
        
        
    let URL = BaseUrl + `admin/product/masterentries/${name}`;
    const response = await http.get(URL)
        let responseData = response.data.data;
        const sortedData = responseData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        if(response.data.success === true)
        {
            setData(sortedData);
            setTempList(responseData);
        }
                
     }catch(err) {
        console.log(err);
        
     }finally{
        setIsLoading(false);
     }
    };
   const addMaster=()=>{
        window.location.href= `/master-entry/add/${name}`;
   }

   const deleteMastervalue = () =>
    {

       var loginDetails = {};
        if (sessionStorage.getItem('authData')) {
           loginDetails = JSON.parse(sessionStorage.getItem('authData'));
        }
       
       let URL = BaseUrl + `admin/product/deletemaster/${mastervalue._id}`;  
       http.delete(URL).then(async (response) => {
               
               if(response.data.success === true){

                   window.location.reload();
               }

           
           }).catch((err) => {
               console.log(err);
               
           })  
       
   }
   const alertPopupclose = () =>
   {
    setVisibleAlert(false);
    setMastervalue('');
   }

    const columns = [
        {
            name: `${(name === 'productgroup')?'Product Group':(name === 'suitablefor')?'Suitable For':(name === 'taxcode')?'Tax Code':'Sectors'} Value`,
            selector: row => row.MasterValue,
            sortable: true,
        },
       {
            name: "Actions",
            selector: row => <div style={{display:"flex"}}>
                <a className='button title-tooltip title-up' data-title='Edit' style={{color: "white"}} onClick={()=>editMaster(row)}><i className="fa fa-pencil" aria-hidden="true"></i></a>
                <a className='button title-tooltip title-up' data-title='Delete' style={{color: "white"}} onClick={()=>deleteMaster(row)}><i className="fa fa-trash" aria-hidden="true"></i></a>
              </div>,
            sortable: false,
        }
    ];
   const handleReset=()=>{
    setSearchTextVal('');
    setData(templist);
     };

    const handleChange = async(value) => {
        setSearchTextVal(value);
        filterData(value);
      };
           

      const filterData = async(value) => {

        let accoun = templist;
       
        const lowercasedValue = value.toLowerCase().trim();
        let result = [];
        if (lowercasedValue.length>0){
            accoun.map((item)=>{
            if(item.MasterValue.toString().toLowerCase().includes(lowercasedValue))
            {
              result.push(item);
            }
          })
          setData(result);
        }else {
        setData(accoun);
      }
      };

    const customStyles = {
        rows: {
            style: {
                minHeight: '25px',
            },
        },
        headCells: {
            style: {
                padding: '8px', // override the cell padding for head cells
                
            },
        },
        cells: {
            style: {
                padding: '8px', // override the cell padding for data cells
                
            },
        },
    };

    const paginationComponentOptions = {
        rowsPerPageText: '',
        rangeSeparatorText: '',
        selectAllRowsItem: true,
        selectAllRowsItemText: '',
        noRowsPerPage:'',
    };
    return (
        
    <section className="dashboard-block">

            <Sidebar />
       <div className="dash-left">
    <Header/>
        <section className="cart-block">
         <div className="container">
            <h1 className="checkout-title">
            {(name === 'productgroup')?'Product Group':(name === 'suitablefor')?'Suitable For':(name === 'taxcode')?'Tax Code':'Sectors'} Master</h1>

            <div className="right-table-outer-wrap">
            <div className="table-filter search-orders">
                    <div className='table-search-reset'>
                    <div className="table-search">
                        <input type="text" value={searchTextVal} onChange={(e) => handleChange(e.target.value)} className="form-control" placeholder={`Search by ${(name === 'productgroup')?'Product Group':(name === 'suitablefor')?'Suitable For':(name === 'taxcode')?'Tax Code':'Sectors'}`} />
                        
                    </div>
                    <button className='submitbtn button search-ord-btn' onClick={(e) => filterData(searchTextVal)}><i className="fa fa-search"></i></button>
                        
                    <button className='resetbtn' type='reset' onClick={()=>handleReset()}>Reset</button>
                    </div>
                    
                    <div className='add-masterbtn'>
                    <button className='addbtn' onClick={()=>addMaster()}>Add</button>
                    </div>
                </div>
            <div className="table_overflow">
                    <DataTable
                                        columns={columns}
                                        noDataComponent={<div className='no-records-found'>Sorry, there is no data matching your keyword/s, try a new search.</div>}
                                        data={data}
                                        customStyles={customStyles}
                                        pagination
                                        paginationPerPage={25}
                                        progressPending={isLoading}
                                        paginationRowsPerPageOptions={[25, 50, 75, 100]}
                                        paginationComponentOptions={paginationComponentOptions }
                                    />
                                    
            </div>
            
           
         </div>
         {visibleAlert && 
                <div className='custom-alert-pop paylater-popup'>
                    <div className='custom-alert-container'>
                        <div className='custom-alert-wrap'>
                            <div className='custom-alert-header'>
                                <h4>Alert</h4>
                            </div>
                            <div className='custom-alert-body'>
                                <p>Are you sure you want to delete?</p>

                                <div className='alert-buttons'>

                                    <a onClick={()=>deleteMastervalue()} className="popup-alert-btn button" >OK</a>
                                    <a onClick={()=>alertPopupclose()} className="popup-alert-btn button">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </section>
        
        <Footer/>
            </div>
            
        </section>
        
    );

}
export default MasterEntry;