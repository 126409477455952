import React, { useEffect, useState } from 'react';
import Header from '../common/layout/header';
import Footer from '../common/layout/footer';
import Sidebar from '../common/sidebar/index'
import { BarChart } from './Barchart';
import { PieChart } from './piechart';
import { Categorysales } from './Categorysales';
import { TopSellProducts } from './Topsellingproducts';
import { TopCustomers } from './TopCustomers';
import { Statistics } from './Statistics';


const Dashboard = () => {
    const [isLoading, setIsLoading] = useState(true);
    // useEffect(() => {
    //     setTimeout(() => {
    //         setIsLoading(false); // End loading after API response
    //     }, 9000); 
    // });

    return (
        <section className="dashboard-block">


            <Sidebar />

            <div className="dash-left">
                <Header />
                <section className="cart-block">
                    <div className="container">
                        <h1 className="checkout-title">
                            Dashboard
                        </h1>
                        <div className="text-note" style={{ fontSize:"14px" }}>Note: When you choose a specific time like Past 1 Day, 7 Days, 30 Days, 90 Days, 180 Days, 1 Year or any custom date range, the system will display data from the current time up until the same time on the last day of the selected period.</div><br/>
                        {/* {isLoading ?(
                            <div className='loading-buffer'></div>
                            ): */}
                        <div className="right-table-outer-wrap">
                            <div className="flex-container">
                                <Statistics />
                                <div className="flex-left revenue-chart-wrap" style={{ verticalAlign: "top" }}>
                                    <BarChart />
                                </div>
                                <div className="flex-right orders-chart-wrap" style={{ verticalAlign: "top" }}>
                                    <Categorysales />
                                </div>
                                {/*<div className="flex-right orders-chart-wrap" style={{ verticalAlign: "top" }}>
                                    <PieChart />
                                </div>*/}
                                <div className="flex-left customers-chart-wrap" style={{ verticalAlign: "top" }}>
                                    <TopCustomers />
                                </div>
                                <div className="flex-right products-chart-wrap" style={{ verticalAlign: "top" }}>
                                    <TopSellProducts />
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <Footer />
            </div>

        </section>);



}

export default Dashboard;