import React, { Component } from 'react';
import { http } from '../helper';
import Header from '../common/layout/header';
import Footer from '../common/layout/footer';
import CommonAlert from '../common/Commonalert';
import { BaseUrl } from '../common/config';
import Loader from '../common/loader';

class Forgotpassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loginStatus: "",
      loginMessage: "",
      isLoading: false,
      alertMessage: '',
    };

    this.emailChange = this.emailChange.bind(this);
    
  }



  validateEmail(email) {
    const trimmedEmail = email.trim(); // Removes leading and trailing spaces
    //var emailRegex = /^[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/;
    var emailRegex = /^[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g;
    if (!emailRegex.test(trimmedEmail)) {
      return false;
    } else {
      return true;
    }
  }

  

  emailChange(event) {
    this.setState({
      email: event.target.value
    });
    this.setState({ loginStatus: "", loginMessage: "", alertPopup:false });
  }

 

  loginOnSubmit = (event) => {
    event.preventDefault();
    this.setState({ alertPopup:false });
   
    let URL = BaseUrl + "admin/forgotpassword";
    if (this.validateEmail(this.state.email)) {
      let payload = {
        emailaddress: this.state.email.trim(),
      }
      this.setState({ isLoading: true });
      http.post(URL, payload).then((response) => {
          if (response.data.success === true) {
            //window.location = '/home';
          //this.setState({alertPopup: true, alertMessage: 'For demo purpose we had disabled the email functionality!!!'});
          this.setState({alertPopup: true, alertMessage: 'We have sent a password reset link to your email. It may take a few minutes. Please check your inbox or spam.'});
         
          }
        this.setState({ isLoading: false });
      }).catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 400) {
          this.setState({
            alertPopup: true,
            alertMessage: error.response.data.message,
            isLoading: false, // Stop loading
          });
        } 
        else{
          this.setState({ isLoading: false, alertPopup: false});
        }
      
      }
    
    )
    }
    else {
      this.setState({ loginStatus: 401, alertPopup: false });
      this.setState({ loginMessage: "Please enter valid Email Address" });
    }

  }

  render() {
    return (
      <>
        {this.state.alertPopup === true && ( <CommonAlert title='Alert' message={this.state.alertMessage}/> )}
        <div className='login-page' >
        <Header />
        { !this.state.isLoading && <div className="login">
          <div className="form">
            <div className="login-form">
              <h2>Reset Password</h2>
              <p>Please enter your email address, We'll send you a link to reset your password.</p>
              
              <div className='input-field'>
                <input type="text" id="accountEmail" value={this.state.email} placeholder="Email Address" required onChange={this.emailChange} />
                {(this.state.loginStatus) ? (this.state.loginStatus === 401 ? <div className='error-msg'>{this.state.loginMessage}</div> : "") : ""}
              </div>
              
              <button type="button" onClick={this.loginOnSubmit}>Submit</button>

            </div>
            
          </div>
            
          <div className="back-to-cat">
           {!localStorage.getItem("isLoggedIn") && 
                 <a className="back-btn" href="/"><i className="fa fa-arrow-left"></i> Back to Home</a>
       
           }
            {localStorage.getItem("isLoggedIn") && 
              <a className="back-btn" href="/"><i className="fa fa-arrow-left"></i> Back to Home</a>
            }
          </div>

        </div>
        }
      
        {this.state.isLoading && <Loader propsClassName="load-lag" />}
        <Footer />
        </div>
      </>
    )
  }
}

export default Forgotpassword;