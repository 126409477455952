import React, { useState, useEffect } from 'react';
import Header from '../common/layout/header'; 
import Footer from '../common/layout/footer'; 
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
import CommonAlert from '../common/Commonalert';
import { http } from '../helper';

const SessionSettings = () =>{

 
    const [isChecked, setIsChecked] = useState(false);
    const [alertPopup,setAlertPopup] = useState(false);
    const [alertMessage,setAlertMessage] = useState([]);
    const [isLoading, setIsLoading] = useState(true); 
    const [showPopup, setShowPopup] = useState(false);
  if(!sessionStorage.getItem("isLoggedIn")){
    window.location.href = '/';
  }
  
  if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_settings'))
    {
      window.location.href = '/unauthorized';
    }

  const btnsubmit = ()=>{
    const element = document.getElementById(
        "clear_session_btn"
      );
      element.classList.add("loader-btn");
    setShowPopup(true);
    
  }

  const sessionclear = ()=>{
    
    let payload = {
         clearsession: (isChecked)?1:0,
    }
 
     let URL = BaseUrl + "admin/deletesessions";
     http.delete(URL, payload).then(async (response) => {
            
        if (response.data.success === true) {
            const element = document.getElementById(
                "clear_session_btn"
              );
              element.classList.remove("loader-btn");
              setShowPopup(false);
              setAlertPopup(true);
              setAlertMessage("Session deleted successfully for all the customers!");
            }
      }).catch((err) => {
        const element = document.getElementById(
            "clear_session_btn"
          );
          element.classList.remove("loader-btn");
         console.log(err);
         
      })
   
   }   

   const closePopup = () => {
    const element = document.getElementById(
        "clear_session_btn"
      );
      element.classList.remove("loader-btn");
    setShowPopup(false);
   
  };

  

    return (<section className="dashboard-block">
{alertPopup && ( <CommonAlert title='Alert' message={alertMessage}/> )}
    
    <Sidebar />
   
<div className="dash-left">
<Header/>
    <section className="cart-block">
        <div className="container account-details">
        <h1 className="checkout-title">Delete Sessions</h1>

        <div className="right-table-outer-wrap">
       
        <div className='form-field'>
                    
                        <div className='input-field col-1'>
                          
                          <button className='clear-session' id="clear_session_btn" onClick={btnsubmit}>Delete Sessions</button>
                          <p className='text-note'>(Click the above button to delete the sessions of all customers. This action will terminate all active sessions for every customer, effectively logging them out from the system. Use this feature to enforce a system-wide logout.)</p>
                        </div>

                </div>
        </div>
        </div>
        <Footer/> 
    </section>
    
    
        </div>
        {showPopup && (
            <div className="custom-alert-pop paylater-popup">
              <div className="custom-alert-container">
                <div className="custom-alert-wrap">
                  <div className="custom-alert-header">
                    <h4>Alert</h4>
                  </div>
                  <div className="custom-alert-body">
                    <p>Are you sure you want to delete the customer sessions?</p>

                    <div className="alert-buttons">
                      <button
                        onClick={sessionclear}
                        className="popup-alert-btn button"
                      >
                        Ok
                      </button>
                      <button
                        onClick={closePopup}
                        className="popup-alert-btn button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}        
    </section>
    

);

}
export default SessionSettings;