import React, { useEffect, useState } from 'react';
import { http } from '../helper';
import { BaseUrl } from '../common/config';
export const Statistics = ({ }) => {

    const priceFormat = (price) => {
        return price.toLocaleString("en-US", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 2,
        });
    };



    const [totalCustomers, setTotalCustomers] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [invoicedOrders, setInvoicedOrders] = useState(0);
    const [cancelledOrders, setCancelledOrders] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        let URL2 = BaseUrl + "admin/getstatistics";
        http.get(URL2).then((response) => response.data.data).then((response) => {
            setTotalCustomers(response.totalCustomers);
            setCancelledOrders(response.cancelledOrders);
            setInvoicedOrders(response.invoicedOrders);
            setTotalOrders(response.totalOrders);
            setTotalRevenue(response.totalRevenue[0].amount);
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
        })

    }, []);


    let loadingbuffer = isLoading ? 'loading-buffer':'';
    return (
       
         <div className={`chart-high-customers flex ${loadingbuffer}`} style={{ verticalAlign: "top" }}>
            <div className="statistics stats-total-orders">
                <div className="stat-hdr">
                    <div className="stat-icon"><i class="fa fa-signal"></i></div>
                    <div className="stat-count">{totalOrders}</div>
                </div>
                <div className="stat-body">
                    <p>Total Orders </p>
                </div>
            </div>
            <div className="statistics stats-total-revenue">
                <div className="stat-hdr">
                    <div className="stat-icon"><i class="fa fa-line-chart"></i></div>
                    <div className="stat-count">{priceFormat(totalRevenue)}</div>
                </div>
                <div className="stat-body">
                    <p>Total Revenue</p>
                </div>
            </div>
            <div className="statistics stats-total-customers">
                <div className="stat-hdr">
                    <div className="stat-icon"><i class="fa fa-users"></i></div>
                    <div className="stat-count">{totalCustomers}</div>
                </div>
                <div className="stat-body">
                    <p>Customers - Placed Orders</p>
                </div>
            </div>
            <div className="statistics stats-invoice-orders">
                <div className="stat-hdr">
                    <div className="stat-icon"><i class="fa fa-file-text"></i></div>
                    <div className="stat-count">{invoicedOrders}</div>
                </div>
                <div className="stat-body">
                    <p>Invoiced Orders</p>
                </div>
            </div>
            <div className="statistics stats-canceled-orders">
                <div className="stat-hdr">
                    <div className="stat-icon"><i class="fa fa-window-close"></i></div>
                    <div className="stat-count">{cancelledOrders}</div>
                </div>
                <div className="stat-body">
                    <p>Cancelled Orders </p>
                </div>
            </div>
        </div>
    
    );
};