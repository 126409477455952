import React, { useState, useEffect } from 'react';
import Header from '../common/layout/header'; 
import Footer from '../common/layout/footer'; 
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
import CommonAlert from '../common/Commonalert';
import { http } from '../helper';
const SiteSettings = () =>{

 
    const [copyright_txt, setCopyright_Txt] = useState([]);
    const [minimum_orderamt, setMinimum_Orderamt] = useState([]);
    const [credit_limit, setCredit_Limit] = useState([]);
    const [discountOption, setDiscountOption] = useState(0);
    const [errormsg,setErrormsg] = useState({});
    const [errmessage, setErrMessage] = useState({});
    const [alertPopup,setAlertPopup] = useState(false);
    const [alertMessage,setAlertMessage] = useState([]);

    
  if(!sessionStorage.getItem("isLoggedIn")){
    window.location.href = '/';
  }
  
  if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_settings'))
    {
      window.location.href = '/unauthorized';
    }

    
    useEffect(()=>{

        var loginDetails = {};
        if (window.sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(window.sessionStorage.getItem('authData'));
        }

        let URL = BaseUrl + "admin/settings";
        http.get(URL).then((response) => {
            let responseData = response.data.data;
           
            if(response.data.success === true)
            {
                setCopyright_Txt(responseData[0].copyrightText);
                setMinimum_Orderamt(responseData[0].minimumOrderAmount);
                setCredit_Limit(responseData[0].creditLimitPay);
                setDiscountOption(responseData[0].Discountpercent);
            }
                    
        }).catch((err) => {
            console.log(err);
            
        })  
    },[])

    const btnsubmit = (e) =>
    {
        e.preventDefault();
        

        var loginDetails = {};
        if (window.sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(window.sessionStorage.getItem('authData'));
        }
        

        const isValid = formvalidation();
 
        if(isValid)
        {  
                
          let payload = {
              copyrightText   : copyright_txt.trim(),
              minimumOrderAmount: minimum_orderamt,
              creditLimitPay : credit_limit,
              Discountpercent: discountOption,
           }
       
           let URL = BaseUrl + "admin/settings";
           http.post(URL, payload).then(async (response) => {
                  
                 if (response.data.success === true) {
                    setAlertPopup(true);
                    setAlertMessage('Site Settings have been updated successfully'); 
                    //window.location = '/';
                   }
            }).catch((err) => {
               console.log(err);
               
            })
        }
    }

    const formvalidation = () =>
    {
        
        const errmessage = {};
        const errmsg = {};
        let isValid = true;
        if(copyright_txt == '' )
        {
            errmessage.copyrighttxterr = "Copyright Text is Required";
            errmsg.cpytxt = true;
            isValid = false;
        }
       
        if(minimum_orderamt == '' )
        {
            errmessage.minimumorder_amterr = "Minimum Order Amount is Required";
            errmsg.moamt = true;
            isValid = false;
        }

        if(credit_limit == '' )
            {
                errmessage.credit_limiterr = "Credit Limit is Required";
                errmsg.crelim = true;
                isValid = false;
            }
      
        if(isValid)
        {
            errmessage.copyrighttxterr = '';
            errmessage.minimumorder_amterr = '';
            errmessage.credit_limiterr = '';

            errmsg.cpytxt = false;
            errmsg.moamt = false;
            errmsg.crelim = false;
            setErrMessage(errmessage);
            return isValid;
        }
        else{
            
            setErrMessage(errmessage);
            setErrormsg(errmsg);
            
            return isValid;
        }
        
    }

  const handleOnChange = (e) =>{
    const re = /^[0-9\b]+$/;
    if(e.target.name ==="copyright_txt")
    {
        setCopyright_Txt(e.target.value);
        if(errmessage.copyrighttxterr != '')
        {
           errormsg.cpytxt = false;
        }
        
        setErrormsg(errormsg);
    }
    if(e.target.name ==="minimum_orderamt")
    {
        if (e.target.value === '' || re.test(e.target.value)) {
        setMinimum_Orderamt(e.target.value);
        }
        if(errmessage.minimumorder_amterr != '')
        {
           errormsg.moamt = false;
        }
        
        setErrormsg(errormsg);
    }
    if(e.target.name ==="credit_limit")
        {
            if (e.target.value === '' || re.test(e.target.value)) {
            setCredit_Limit(e.target.value);
            }
            if(errmessage.credit_limiterr != '')
            {
               errormsg.crelim = false;
            }
            
            setErrormsg(errormsg);
        }

        if(e.target.name ==="discountOption")
            {
                if (e.target.value === '' || re.test(e.target.value)) {
                    setDiscountOption(e.target.value);
                }
              
            }
       
   
    setAlertPopup(false);
  }

    return (<section className="dashboard-block">
{alertPopup && ( <CommonAlert title='Alert' message={alertMessage}/> )}
    
            <Sidebar />
   
    <div className="dash-left">
    <Header/>
        <section className="cart-block">
         <div className="container account-details">
            <h1 className="checkout-title">Site Settings</h1>

            <div className="right-table-outer-wrap">
            <div className='form-field freeproduct-fieldset sitesettings-fieldset form-container-xs'>
                        <form className='client-detail-form'>
                            <div className='input-field col-1'>
                                <label for='name'>Copyright Text</label>
                                <input type='text' name='copyright_txt' value={copyright_txt} onChange={handleOnChange} />
                                {errormsg.cpytxt && <div style={{color:"red"}}>{errmessage.copyrighttxterr}</div> }  
                            </div>
                            <div className='input-field col-1'>
                                <label for='name'>Minimum Order Amount</label>
                                <input type='text' name='minimum_orderamt' value={minimum_orderamt} onChange={handleOnChange} />
                                {errormsg.moamt && <div style={{color:"red"}}>{errmessage.minimumorder_amterr}</div> }  
                            </div>
                            <div className='input-field col-1'>
                                <label for='name'>Default Credit Limit</label>
                                <input type='text' name='credit_limit' value={credit_limit} onChange={handleOnChange} />
                                {errormsg.crelim && <div style={{color:"red"}}>{errmessage.credit_limiterr}</div> }  
                            </div>
                            <div className='input-field col-1'>
                                <label for='name'>Discount % for all Categories Products</label>
                                <input type='text' name='discountOption' value={discountOption} onChange={handleOnChange} />
                                
                            </div>
                           
                            <div className='btnfield'>
                            <button type="submit" name="btnsubmit" onClick={btnsubmit}>Submit</button>
                            </div>
                           
                            
                        </form>
                    </div>
           </div>
         </div>
         <Footer/> 
        </section>
       
            </div>
          
        </section>);

}
export default SiteSettings;