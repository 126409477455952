import React, { Component} from 'react';
import Header from '../common/layout/header';
import Footer from '../common/layout/footer'; 
import DataTable from 'react-data-table-component';
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
import moment from 'moment';
import { http } from '../helper';
import { capitalizeFirstLetter } from "../utilityfunctions";
 
 
class Freeproductspdf extends Component {


    
    constructor(props) {
        super(props);
        this.state = {
            searchTextVal:"",
            freeproductspdflist: [],
            templist :[],
            loading: true,
            popupView: "",
            visibleAlert:false,
            freeproductpdfvalue : '',
        };

        if(!sessionStorage.getItem("isLoggedIn")){
            window.location.href = '/';
        }
        if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_newproducts'))
            {
              window.location.href = '/unauthorized';
            }
    
     }

    getFreeproductpdflist() {
        var loginDetails = {};
            if (sessionStorage.getItem('authData')) {
               loginDetails = JSON.parse(sessionStorage.getItem('authData'));
            } 
        let URL = BaseUrl + "admin/listpdf";
        http.get(URL).then((response) => {
            this.setState({ freeproductspdflist: response.data.data, loading: false, templist:response.data.data});
        }).catch((err) => {
            console.log(err);
        })
    }

   

    editfreeproductpdfDetails = (editid)=>{
        //setEditfreeproduct('edit');
      
        window.location.href=`/newproducts/edit/${editid}`;
        //window.location.href = `/holiday/edit/${editid}`;
      };
      deleteFreepdf =(value) =>
      {
        this.setState({visibleAlert:true,freeproductpdfvalue:value});
      }
      deletefreeproductspdf = () =>
      {
        var loginDetails = {};
            if (sessionStorage.getItem('authData')) {
               loginDetails = JSON.parse(sessionStorage.getItem('authData'));
            } 
        let URL = BaseUrl + `admin/deletePDF/${this.state.freeproductpdfvalue._id}`;  
        http.delete(URL).then(async (response) => {
            
            if(response.data.success === true){

                window.location.reload();
            }

        
        }).catch((err) => {
            console.log(err);
            
        });  


      }

      alertPopupclose = () =>
      {
          this.setState({visibleAlert:false,freeproductvalue:''});
      }

    componentDidMount() {
        this.getFreeproductpdflist();
    }

    addfreeproduct = () =>
      {
        window.location.href='/newproducts/add'
      }
    
    handleReset=()=>{
        this.setState({searchTextVal:'',freeproductspdflist:this.state.templist});
     };

    handleChange = async(value) => {
        this.setState({searchTextVal:value});
        this.filterData(value);
      };
           

      filterData = async(value) => {

        let accoun = this.state.templist;
      
        const lowercasedValue = value.toLowerCase().trim();
        let result = [];
        if (lowercasedValue.length>0){
            accoun.map((item)=>{
            if(item.PDFTitle.toString().toLowerCase().includes(lowercasedValue))
            {
              result.push(item);
            }
          })
          this.setState({freeproductspdflist:result});
        }else {
        this.setState({freeproductspdflist:accoun});
      }
      };


    render() {

        const columns = [
            {
                name: "Title",
                selector: row => row.PDFTitle,
                sortable: true,
            },
            {
                name: "Display on Main Page",
                selector: row =>(row.FeaturedPdf)?'Yes':'No',
                sortable: true,
            },
           
            {
                name: "Status",
                selector: row =>capitalizeFirstLetter(row.Status),
                sortable: true,
            }, 
            {
                name: "Sort Order",
                selector: row =>row.sortOrder,
                sortable: true,
            },
            {
                name: "Date Created",
                selector: row => moment(row.createdAt).format('DD-MM-YYYY HH:MM A'),
                sortable: true,
            },       
            {
                name: "Actions",
                selector: row => <div style={{display:"flex"}}>
                    <a className='editbtn button title-tooltip title-up' data-title='Edit' style={{color: "white"}} onClick={()=>this.editfreeproductpdfDetails(row._id)}><i className="fa fa-pencil" aria-hidden="true"></i></a>
                    <a className='editbtn button title-tooltip title-up' data-title='Delete' style={{color: "white"}} onClick={()=>this.deleteFreepdf(row)}><i className="fa fa-trash" aria-hidden="true"></i></a>  
                    
                  
                    </div>,
                sortable: false,
            }
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '25px',
                },
            },
            headCells: {
                style: {
                    padding: '8px', // override the cell padding for head cells
                    
                },
            },
            cells: {
                style: {
                    padding: '8px', // override the cell padding for data cells
                    
                },
            },
        };

        const paginationComponentOptions = {
            rowsPerPageText: '',
            rangeSeparatorText: '',
            selectAllRowsItem: true,
            selectAllRowsItemText: '',
            noRowsPerPage:'',
        };

        return (
            <>
                <div className="dashboard-block">

                        <Sidebar />
                 
                    <div className="dash-left">
                        <Header />

                        <section className="cart-block">
                        <div className="container">
                            <h1 className="checkout-title">New Products</h1>  

                            <div className="right-table-outer-wrap">
                            <div className="table-filter search-orders">
                              <div className='table-search-reset'>
                                <div className="table-search">
                                    <input type="text" value={this.state.searchTextVal} onChange={e => this.handleChange(e.target.value)} className="form-control" placeholder="Search by Title" />
                                    
                                </div>
                                <button className='submitbtn button search-ord-btn' onClick={e => this.filterData(this.state.searchTextVal)}><i className="fa fa-search"></i></button>
                                 
                                <button className='resetbtn' type='reset' onClick={this.handleReset}>Reset</button>
                              </div>
                             
                              <div className='add-holidaybtn'>
                                <button className='addbtn' onClick={this.addfreeproduct}>Add</button>
                              </div>
                            </div>
                            
                            <div className="table_overflow new-prod-data-table">
                           
                                    <DataTable
                                        columns={columns}
                                        noDataComponent={<div className='no-records-found'>Sorry, there are no new products were matching your keyword, try a new search.</div>}
                                        data={this.state.freeproductspdflist}
                                        customStyles={customStyles}
                                        pagination
                                        paginationPerPage={25}
                                        progressPending={this.state.loading}
                                        paginationRowsPerPageOptions={[25, 50, 75, 100]}
                                        paginationComponentOptions={paginationComponentOptions }
                                    />
                                    
                            </div>
                        </div>
                        </div>
                        </section>

                        <Footer />
                    </div>
                </div>

                {this.state.visibleAlert === true && 
                <div className='custom-alert-pop paylater-popup'>
                    <div className='custom-alert-container'>
                        <div className='custom-alert-wrap'>
                            <div className='custom-alert-header'>
                                <h4>Alert</h4>
                            </div>
                            <div className='custom-alert-body'>
                                <p>Are you sure you want to delete?</p>

                                <div className='alert-buttons'>

                                    <a onClick={this.deletefreeproductspdf.bind(this)} className="popup-alert-btn button" >OK</a>
                                    <a onClick={this.alertPopupclose.bind(this)} className="popup-alert-btn button">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>} 

            </>
        );
    }
}

export default Freeproductspdf;